import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ProductSer from '../components/product_ser.vue';
import ProductInput from '../components/product_input.vue';

formCreate.component('input_product_ser', ProductSer);
formCreate.component('input_product', ProductInput);
let that = null;

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'main_form',
    };
  },
  methods: {
    setRule(item) {
      const v = item;
      if (v.field === 'interestType') {
        v.on = {
          ...v.on,
          change: (val) => {
            this.changeTypeFun(val);
            this.changeTypePro(val);
          },
        };
      } else if (
        ['num', 'interestNum', 'discountAmount', 'interestRatio', 'auditPrice'].includes(v.field)
      ) {
        v.props = {
          ...v.props,
          min: 0,
        };
        if (v.field === 'num') {
          v.props = {
            ...v.props,
            precision: 0,
          };
        } else {
          v.props = {
            ...v.props,
            precision: 2,
          };
          if (v.field === 'interestRatio') {
            v.props = {
              ...v.props,
              max: 1,
            };
          }
        }
      } else if (v.field === 'productSeriesList') {
        v.on = {
          ...v.on,
          change: (val) => {
            const productList = that.getRule('productList');
            let obj = {};
            if (val && val.tableData && val.tableData.length > 0) {
              if (val.tableData.length > 1) {
                obj.productLevelCodes = val.productLevelCode.split(',');
              } else {
                obj = val;
              }
            }
            productList.props = {
              ...productList.props,
              params: obj,
            };
          },
        };
      } else if (['interestIcon', 'signExample'].includes(v.field)) {
        v.props = {
          ...v.props,
          limit: 1,
        };
      } else if (v.field === 'expireDay') {
        v.props = {
          ...v.props,
          min: 0,
          precision: 0,
        };
      }
      return v;
    },
    changeTypePro(val) {
      if (val === 'tasting') {
        const productSeriesList = that.getRule('productSeriesList');
        const productList = that.getRule('productList');
        productSeriesList.props = {
          ...productSeriesList.props,
          functionCode: 'product_ser_list',
        };
        productList.props = {
          ...productList.props,
          functionCode: 'product_radio_list',
        };
      } else if (val === 'interest') {
        const productSeriesList = that.getRule('productSeriesList');
        const productList = that.getRule('productList');
        productSeriesList.props = {
          ...productSeriesList.props,
          functionCode: 'product_ser_checkbox_list',
        };
        productList.props = {
          ...productList.props,
          functionCode: 'product_list',
        };
      }
    },
    changeTypeFun(val) {
      if (val === 'tasting') {
        this.hiddenFields(true, [
          'discountAmount',
          'startInterestTime',
          'endInterestTime',
          'tag',
          'amountUnit',
          'startReleaseTime',
          'endReleaseTime',
          'expireTime',
          'interestName',
          'beRepeat',
          'interestRatio',
          'interestUnit',
          'interestNum',
          'gift',
        ]);
        this.hiddenFields(false, [
          'settlement',
          'auditType',
          'productSeriesList',
          'productList',
          'num',
          'baseUnit',
          'auditPrice',
          'interestIcon',
          'signExample',
          'expireDay',
        ]);
      } else if (val === 'cash_discount') {
        this.hiddenFields(true, [
          'startInterestTime',
          'endInterestTime',
          'baseUnit',
          'startReleaseTime',
          'endReleaseTime',
          'productSeriesList',
          'expireTime',
          'productList',
          'beRepeat',
          'interestName',
          'interestRatio',
          'interestNum',
          'interestUnit',
          'auditPrice',
          'signExample',
          'num',
          'gift',
        ]);
        this.hiddenFields(false, [
          'settlement',
          'auditType',
          'discountAmount',
          'amountUnit',
          'tag',
          'interestIcon',
          'expireDay',
        ]);
      } else if (val === 'gift') {
        this.hiddenFields(true, [
          'discountAmount',
          'startInterestTime',
          'endInterestTime',
          'tag',
          'amountUnit',
          'baseUnit',
          'productSeriesList',
          'productList',
          'interestRatio',
          'num',
        ]);
        this.hiddenFields(false, [
          'settlement',
          'auditType',
          'startReleaseTime',
          'endReleaseTime',
          'expireTime',
          'beRepeat',
          'interestName',
          'interestNum',
          'interestUnit',
          'auditPrice',
          'interestIcon',
          'signExample',
          'gift',
          'expireDay',
        ]);
      } else if (val === 'interest') {
        this.hiddenFields(true, [
          'auditType',
          'discountAmount',
          'tag',
          'amountUnit',
          'baseUnit',
          'interestName',
          'interestNum',
          'interestUnit',
          'auditPrice',
          'signExample',
          'num',
          'gift',
        ]);
        this.hiddenFields(false, [
          'settlement',
          'startInterestTime',
          'endInterestTime',
          'productSeriesList',
          'productList',
          'interestRatio',
          'interestIcon',
        ]);
      }
    },
    formComplete() {
      that = this;
      const { code, row } = this.formConfig;
      if (code === 'add') {
        this.setValue({
          amountUnit: '元',
          // signExample: '1',
          // interestIcon: '1',
        });
        this.hiddenFields(true, [
          'settlement',
          'auditType',
          'discountAmount',
          'startInterestTime',
          'endInterestTime',
          'tag',
          'amountUnit',
          'baseUnit',
          'startReleaseTime',
          'endReleaseTime',
          'productSeriesList',
          'expireTime',
          'productList',
          'beRepeat',
          'interestName',
          'interestRatio',
          'interestNum',
          'interestUnit',
          'auditPrice',
          'interestIcon',
          'signExample',
          'num',
          'gift',
          'expireDay',
        ]);
        this.hiddenFields(true, ['interestRatio']);
      }
      if (code !== 'add') {
        this.changeTypeFun(row.interestType);
        this.changeTypePro(row.interestType);
        // 获取详情
        this.getDetail();
      }
    },
    getDetail() {
      const { row } = this.formConfig;
      request
        .get('/cps/fxh/interestRuleController/findById', {
          id: row.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            // console.log(result, row);
            this.setValue({
              ...result,
              productList: {
                materialName: (result.productList || []).map((el) => el.productName).join(','),
                materialCode: (result.productList || []).map((el) => el.productCode).join(','),
                tableData: (result.productList || []).map((el) => ({
                  ...el,
                  materialCode: el.productCode,
                  materialName: el.productName,
                })),
              },
              productSeriesList: {
                productLevelName: (result.productSeriesList || [])
                  .map((el) => el.productName)
                  .join(','),
                productLevelCode: (result.productSeriesList || [])
                  .map((el) => el.productCode)
                  .join(','),
                tableData: (result.productSeriesList || []).map((el) => ({
                  ...el,
                  productLevelCode: el.productCode,
                  productLevelName: el.productName,
                })),
              },
            });
          }
        });
    },
    submit() {
      const formData = this.getFormData();
      const { row, code } = this.formConfig;
      if (!formData) return;
      const requestParam = {
        ...formData,
      };
      if (formData.productList && formData.productList.tableData) {
        requestParam.productList = formData.productList.tableData.map((el) => ({
          productCode: el.materialCode,
          productName: el.materialName,
        }));
      }
      if (formData.productSeriesList && formData.productSeriesList.tableData) {
        requestParam.productSeriesList = formData.productSeriesList.tableData.map((el) => ({
          productCode: el.productLevelCode,
          productName: el.productLevelName,
        }));
      }
      requestParam.detailList = formData.detailList;
      console.log('🚀 ~ submit ~ requestParam:', requestParam);
      const requestUrl = '/cps/fxh/interestRuleController/saveInterestRule';
      const reqType = 'post';
      if (code === 'edit') {
        // reqType = 'patch';
        // requestUrl = '/cps/v1/terminalConfigurationRuleImage/update';
        requestParam.id = row.id;
      }
      request[reqType](requestUrl, requestParam).then(() => {
        this.$message.success('操作成功!');
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
};
