import Page from '@/found/components/table_page';
import Form from './form';

export default {
  extends: Page,
  components: { Form },
  data() {
    return {
      formConfig: {},
      modalConfig: {},
      exportModalName: 'cps',
    };
  },
  created() {
    this.getConfigList('main_list');
  },
  methods: {
    beforeCellClick({ column, row }) {
      return true;
    },
    modalClick({ val, row }) {
      this.formConfig.code = val.code;
      this.modalConfig.title = val.name;
      this.formName = 'Form';
      if (val.code === 'add') {
        this.formConfig.row = {};
        this.openFull();
      } else if (['edit', 'view'].includes(val.code)) {
        this.formConfig.row = row;
        this.openFull();
      }
    },
  },
};
