<template>
  <div class="file-box" :class="limit === fileList.length ? 'limit-class' : ''">
    <el-upload
      :headers="headerObj"
      action="/upload/uploadController/uploadOssList"
      :with-credentials="true"
      list-type="picture-card"
      :auto-upload="true"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :limit="limit"
      :file-list="fileList"
      show-file-list
      accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
      :disabled="disabled"
    >
      <i slot="default" class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Storage from '@/found/utils/storage';

export default {
  props: {
    value: {
      type: [Array, Object, String],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      headerObj: {
        loginUserToken: Storage.l.get('TokenKey'),
      },
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: '',
    };
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.fileList = val
            ? val.map((el) => ({
              ...el,
              //   uid: el.id || new Date().getTime() + Math.random() * 10000,
            }))
            : [];
        } else if (typeof val === 'string') {
          if (val) {
            this.fileList = [{ url: val }];
          } else {
            this.fileList = [];
          }
        } else if (val) {
          this.fileList = [val || {}];
        }
        // console.log(val, this.fileList, '99888888');
      },
    },
  },
  methods: {
    handleRemove(file, fileList) {
      console.log('removefile', file);
      this.fileList.splice(this.fileList.indexOf(file.fileName));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log('download', file);
    },
    handleSuccess(res) {
      console.log('图片', res);
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.fileList.push({
            fileName: v.fileName,
            pictureUrl: v.url,
            url: v.url,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName: v.objectName,
          });
        });
        this.$emit('input', this.fileList);
        this.$emit('change', this.fileList);
      }
    },
  },
};
</script>

<style scoped lang="less">
.limit-class {
  /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>
