import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DetailList from '../../level_bg_config/components/detail_cus_list.vue';

formCreate.component('detailList', DetailList);
let that = null;

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'main_form',
    };
  },
  methods: {
    setRule(item) {
      const v = item;
      if (v.field === 'terminalConfigurationRuleCode') {
        v.restful = '/cps/fxh/terminalConfigurationRuleController/findByConditions'; // 请求接口
        v.restfulParams = {}; // 请求参数
        v.optionsKey = {
          label: 'terminalConfigurationRuleName',
          value: 'terminalConfigurationRuleCode',
        };
        v.restType = 'GET';
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'terminalConfigurationRuleName',
        };
        v.on = {
          ...v.on,
          getLabel: (e) => {
            this.setValue({
              terminalConfigurationRuleName: e,
            });
          },
          change: (e, record) => {
            // console.log(e, record, '8999');
            const detailList = that.getRule('detailList');
            detailList.props = {
              ...detailList.props,
              code: e,
            };
          },
        };
      }
      return v;
    },
    formComplete() {
      that = this;
      const { code } = this.formConfig;
      if (code !== 'add') {
        // 获取详情
        this.getDetail();
      }
    },
    getDetail() {
      const { row } = this.formConfig;
      request
        .get('/cps/v1/terminalConfigurationRuleImage/findById', {
          id: row.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            // console.log(result, row);
            this.setValue({
              ...result,
            });
          }
        });
    },
    submit() {
      const formData = this.getFormData();
      const { row, code } = this.formConfig;
      if (!formData) return;
      const requestParam = {
        imageConfigName: formData.imageConfigName,
        imageConfigCode: formData.imageConfigCode,
        terminalConfigurationRuleCode: formData.terminalConfigurationRuleCode,
        terminalConfigurationRuleName: formData.terminalConfigurationRuleName,
      };
      requestParam.detailList = formData.detailList;
      let requestUrl = '/cps/v1/terminalConfigurationRuleImage/save';
      const reqType = 'post';
      if (code === 'edit') {
        // reqType = 'patch';
        requestUrl = '/cps/v1/terminalConfigurationRuleImage/update';
        requestParam.id = row.id;
      }
      request[reqType](requestUrl, requestParam).then(() => {
        this.$message.success('操作成功!');
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
};
