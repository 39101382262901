var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "table-top" },
        [
          !_vm.disabled
            ? _c("el-button", { on: { click: _vm.addRow } }, [
                _vm._v("添加一行"),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vxe-table",
        {
          attrs: {
            border: "",
            align: "center",
            size: "mini",
            data: _vm.tableData,
            "edit-config": { trigger: "click", mode: "row" },
          },
        },
        [
          _c("vxe-column", {
            attrs: { align: "center", title: "序号", type: "seq", width: "60" },
          }),
          _c("vxe-column", {
            attrs: { field: "detailName", title: "档案等级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input", {
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: "请输入档案等级",
                      },
                      on: { change: _vm.changeEvent },
                      model: {
                        value: row.detailName,
                        callback: function ($$v) {
                          _vm.$set(row, "detailName", $$v)
                        },
                        expression: "row.detailName",
                      },
                    }),
                    _vm._v('" '),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-column", {
            attrs: { field: "url", title: "背景图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("UploadImage", {
                          attrs: { value: row.url, disabled: _vm.disabled },
                          on: {
                            change: function (files) {
                              return _vm.changeFiles(files, row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          !_vm.disabled
            ? _c("vxe-column", {
                attrs: { title: "操作", "show-overflow": "", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-button", {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeEvent(row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2269260287
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }