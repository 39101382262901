<!--  -->
<template>
  <div>
    <div class="table-top">
      <el-button v-if="!disabled" @click="addRow">添加一行</el-button>
    </div>
    <vxe-table
      border
      align="center"
      size="mini"
      :data="tableData"
      :edit-config="{ trigger: 'click', mode: 'row' }"
    >
      <vxe-column align="center" title="序号" type="seq" width="60" />
      <vxe-column field="detailName" title="档案等级">
        <template #default="{ row }">
          <el-input
            :disabled="disabled"
            v-model="row.detailName"
            @change="changeEvent"
            placeholder="请输入档案等级"
          />"
        </template>
      </vxe-column>
      <vxe-column field="url" title="背景图片">
        <template #default="{ row }">
          <div>
            <UploadImage
              @change="(files) => changeFiles(files, row)"
              :value="row.url"
              :disabled="disabled"
            ></UploadImage>
          </div>
        </template>
      </vxe-column>
      <vxe-column v-if="!disabled" title="操作" show-overflow width="80">
        <template #default="{ row }">
          <el-button
            type="danger"
            @click="removeEvent(row)"
            icon="el-icon-delete"
            circle
          ></el-button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import UploadImage from '../../components/upload_image';

export default {
  components: { UploadImage },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    terminalConfigurationRuleRow: {
      type: Object,
      default: () => ({}),
    },
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    removeEvent(row) {
      this.tableData = this.tableData.filter((item) => item.uid !== row.uid);
      this.changeEvent();
    },
    addRow() {
      this.tableData.push({
        detailName: '',
        url: '',
        uid: new Date().getTime() + Math.random() * 10000,
      });
      this.changeEvent();
    },
    getLevelDetail() {
      if (!this.code) {
        return;
      }
      request
        .get('/cps/fxh/terminalConfigurationRuleController/findByCode', {
          code: this.code,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            // console.log(result, row);
            // this.detailName = result
            this.tableData = ((result && result.details) || []).map((el) => ({
              //   ...el,
              //   id: el.id,
              detailName: el.detailName,
              url: '',
              uid: new Date().getTime() + Math.random() * 10000,
            }));
            this.changeEvent();
          }
        });
    },
    changeFiles(files, row) {
      //   row.url = files[0].url;
      console.log(files, row, '888', this.tableData);
      this.tableData = this.tableData.map((el) => {
        if (el.uid === row.uid) {
          el.url = files[0].url;
        }
        return {
          ...el,
        };
      });
      this.changeEvent();
    },
    changeEvent() {
      this.$emit('change', this.tableData);
      this.$emit('input', this.tableData);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.tableData = val
          ? val.map((el) => ({
            ...el,
            uid: el.uid || new Date().getTime() + Math.random() * 10000,
          }))
          : [];
      },
    },
    terminalConfigurationRuleRow: {
      handler(val) {
        if (val) {
          this.getLevelDetail();
        }
      },
      immediate: true,
      deep: true,
    },
    code: {
      handler(val) {
        // console.log(val, '9999');
        this.getLevelDetail();
      },
    },
  },
};
</script>

<style scoped lang="less">
.table-top {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
/deep/.vxe-header--column .vxe-cell--title {
  line-height: inherit !important;
}
</style>
