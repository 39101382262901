var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "file-box",
      class: _vm.limit === _vm.fileList.length ? "limit-class" : "",
    },
    [
      _c(
        "el-upload",
        {
          attrs: {
            headers: _vm.headerObj,
            action: "/upload/uploadController/uploadOssList",
            "with-credentials": true,
            "list-type": "picture-card",
            "auto-upload": true,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            limit: _vm.limit,
            "file-list": _vm.fileList,
            "show-file-list": "",
            accept: ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF",
            disabled: _vm.disabled,
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-plus",
            attrs: { slot: "default" },
            slot: "default",
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, modal: false },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }