import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DetailCusList from '../components/detail_cus_list.vue';

formCreate.component('detailCusList', DetailCusList);
let that = null;
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'main_form',
    };
  },
  methods: {
    handleFieldChange(field, value) {
      // console.log(field, value, '898888');
      this.formData[field] = value;
    },
    setRule(item) {
      const v = item;
      if (v.field === 'effectiveScopeCode') {
        v.props = {
          ...v.props,
        };
        v.on = {
          ...v.on,
          change: (val) => {
            if (val === '1') {
              this.hiddenFields(true, ['detailCusList']);
              this.hiddenFields(false, ['detailList']);
            } else if (val === '2') {
              this.hiddenFields(true, ['detailList']);
              this.hiddenFields(false, ['detailCusList']);
            }
          },
        };
      } else if (v.field === 'terminalConfigurationRuleCode') {
        v.restful = '/cps/fxh/terminalConfigurationRuleController/findByConditions'; // 请求接口
        v.restfulParams = {}; // 请求参数
        v.optionsKey = {
          label: 'terminalConfigurationRuleName',
          value: 'terminalConfigurationRuleCode',
        };
        v.restType = 'GET';
        v.props = {
          ...v.props,
          filterable: true,
          remote: true,
          remoteParams: 'terminalConfigurationRuleName',
        };
        v.on = {
          ...v.on,
          getLabel: (e) => {
            this.setValue({
              terminalConfigurationRuleName: e,
            });
          },
          change: (e, record) => {
            const detailCusList = that.getRule('detailCusList');
            detailCusList.props = {
              ...detailCusList.props,
              code: e,
            };
          },
        };
      } else if (v.field === 'detailList') {
        v.multiple = false;
        v.props = {
          ...v.props,
          // multiple: false,
          // limit: 1,
        };
      }
      return v;
    },
    //   表单完成回调
    formComplete() {
      that = this;
      const { code, row } = this.formConfig;
      if (row.effectiveScopeCode === '1') {
        this.hiddenFields(false, ['detailList']);
        this.hiddenFields(true, ['detailCusList']);
      } else if (row.effectiveScopeCode === '2') {
        this.hiddenFields(true, ['detailList']);
        this.hiddenFields(false, ['detailCusList']);
      }
      if (code === 'edit' || code === 'view') {
        this.getDetail();
      } else {
        this.hiddenFields(false, ['detailList']);
        this.hiddenFields(true, ['detailCusList']);
      }
    },
    getDetail() {
      const { row } = this.formConfig;
      request
        .get('/cps/v1/fxhGradeBackgroundConfig/findById', {
          id: row.id,
        })
        .then((res) => {
          if (res.success) {
            const { result } = res;
            console.log(result, row);
            this.setValue({
              ...result,
              detailList: (result.detailList || []).map((el) => el.url), // 图片limit为1,要取链接
              detailCusList: result.detailList,
            });
          }
        });
    },
    submit() {
      const formData = this.getFormData();
      const { row, code } = this.formConfig;
      if (!formData) return;
      console.log(formData, 'formdata');
      const requestParam = {
        gradeBackgroundName: formData.gradeBackgroundName,
        gradeBackgroundCode: formData.gradeBackgroundCode,
        terminalConfigurationRuleName: formData.terminalConfigurationRuleName,
        terminalConfigurationRuleCode: formData.terminalConfigurationRuleCode,
        effectiveScopeCode: formData.effectiveScopeCode,
      };
      if (requestParam.effectiveScopeCode === '1') {
        if (Array.isArray(formData.detailList)) {
          requestParam.detailList = formData.detailList.map((el) => ({ url: el }));
        } else if (typeof formData.detailList === 'string') {
          requestParam.detailList = [{ url: formData.detailList }];
        }
      } else if (requestParam.effectiveScopeCode === '2') {
        requestParam.detailList = formData.detailCusList;
      }
      let requestUrl = '/cps/v1/fxhGradeBackgroundConfig/save';
      const reqType = 'post';
      if (code === 'edit') {
        // reqType = 'patch';
        requestUrl = '/cps/v1/fxhGradeBackgroundConfig/update';
        requestParam.id = row.id;
      }
      request[reqType](requestUrl, requestParam).then(() => {
        this.$message.success('操作成功!');
        this.$emit('onClose');
        this.$emit('onGetList');
      });
    },
  },
};
